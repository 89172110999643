import {
  CreateSupsWatchlistDto,
  RetrieveSupsWatchlistParams,
  SupsWatchlistPage,
  SupsWatchlistRaw,
  UpdateSupsWatchlistDto,
  SupsWatchlistDetails
} from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_WATCHLISTS = 'watchlists/RETRIEVE_WATCHLISTS';
export const RETRIEVE_WATCHLISTS_SUCCESS = 'watchlists/RETRIEVE_WATCHLISTS_SUCCESS';
export const RETRIEVE_WATCHLISTS_FAILURE = 'watchlists/RETRIEVE_WATCHLISTS_FAILURE';

export const RETRIEVE_WATCHLIST_DETAILS = 'watchlists/RETRIEVE_WATCHLIST_DETAILS';
export const RETRIEVE_WATCHLIST_DETAILS_SUCCESS = 'watchlists/RETRIEVE_WATCHLIST_DETAILS_SUCCESS';
export const RETRIEVE_WATCHLIST_DETAILS_FAILURE = 'watchlists/RETRIEVE_WATCHLIST_DETAILS_FAILURE';

export const RETRIEVE_WATCHLISTS_PAGINATED = 'watchlists/RETRIEVE_WATCHLISTS_PAGINATED';
export const RETRIEVE_WATCHLISTS_PAGINATED_SUCCESS = 'watchlists/RETRIEVE_WATCHLISTS_PAGINATED_SUCCESS';
export const RETRIEVE_WATCHLISTS_PAGINATED_FAILURE = 'watchlists/RETRIEVE_WATCHLISTS_PAGINATED_FAILURE';

export const CREATE_WATCHLIST = 'watchlists/CREATE_WATCHLIST';
export const CREATE_WATCHLIST_SUCCESS = 'watchlists/CREATE_WATCHLIST_SUCCESS';
export const CREATE_WATCHLIST_FAILURE = 'watchlists/CREATE_WATCHLIST_FAILURE';

export const UPDATE_WATCHLIST = 'watchlists/UPDATE_WATCHLIST';
export const UPDATE_WATCHLIST_SUCCESS = 'watchlists/UPDATE_WATCHLIST_SUCCESS';
export const UPDATE_WATCHLIST_FAILURE = 'watchlists/UPDATE_WATCHLIST_FAILURE';

export const DELETE_WATCHLIST = 'watchlists/DELETE_WATCHLIST';
export const DELETE_WATCHLIST_SUCCESS = 'watchlists/DELETE_WATCHLIST_SUCCESS';
export const DELETE_WATCHLIST_FAILURE = 'watchlists/DELETE_WATCHLIST_FAILURE';

export const DUPLICATE_WATCHLIST = 'watchlists/DUPLICATE_WATCHLIST';
export const DUPLICATE_WATCHLIST_SUCCESS = 'watchlists/DUPLICATE_WATCHLIST_SUCCESS';
export const DUPLICATE_WATCHLIST_FAILURE = 'watchlists/DUPLICATE_WATCHLIST_FAILURE';

export const EXPORT_WATCHLIST = 'watchlists/EXPORT_WATCHLIST';
export const EXPORT_WATCHLIST_SUCCESS = 'watchlists/EXPORT_WATCHLIST_SUCCESS';
export const EXPORT_WATCHLIST_FAILURE = 'watchlists/EXPORT_WATCHLIST_FAILURE';

export const CLEAR_WATCHLIST_MODIFICATIONS = 'watchlists/CLEAR_WATCHLIST_MODIFICATIONS';
export interface ClearWatchlistModificationsAction {
  type: typeof CLEAR_WATCHLIST_MODIFICATIONS;
}

export interface RetrieveWatchlistsAction {
  type: typeof RETRIEVE_WATCHLISTS;
  params?: RetrieveSupsWatchlistParams;
}

export interface RetrieveWatchlistsSuccessAction {
  type: typeof RETRIEVE_WATCHLISTS_SUCCESS;
  watchlists: SupsWatchlistRaw[];
}

export interface RetrieveWatchlistsFailureAction {
  type: typeof RETRIEVE_WATCHLISTS_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveWatchlistDetailsAction {
  type: typeof RETRIEVE_WATCHLIST_DETAILS;
  wathlistId: number;
}

export interface RetrieveWatchlistDetailsSuccessAction {
  type: typeof RETRIEVE_WATCHLIST_DETAILS_SUCCESS;
  watchlist: SupsWatchlistDetails;
}

export interface RetrieveWatchlistDetailsFailureAction {
  type: typeof RETRIEVE_WATCHLIST_DETAILS_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveWatchlistsPaginatedAction {
  type: typeof RETRIEVE_WATCHLISTS_PAGINATED;
  params?: RetrieveSupsWatchlistParams;
}

export interface RetrieveWatchlistsPaginatedSuccessAction {
  type: typeof RETRIEVE_WATCHLISTS_PAGINATED_SUCCESS;
  watchlists: SupsWatchlistPage[];
  total: number;
}

export interface RetrieveWatchlistsPaginatedFailureAction {
  type: typeof RETRIEVE_WATCHLISTS_PAGINATED_FAILURE;
  error?: RequestResult<void>;
}

export interface CreateWatchlistAction {
  type: typeof CREATE_WATCHLIST;
  data: CreateSupsWatchlistDto;
}

export interface CreateWatchlistSuccessAction {
  type: typeof CREATE_WATCHLIST_SUCCESS;
  watchlist: SupsWatchlistRaw;
}

export interface CreateWatchlistFailureAction {
  type: typeof CREATE_WATCHLIST_FAILURE;
  error?: RequestResult<void>;
}

export interface UpdateWatchlistAction {
  type: typeof UPDATE_WATCHLIST;
  data: UpdateSupsWatchlistDto;
  watchlistId: number;
}

export interface UpdateWatchlistSuccessAction {
  type: typeof UPDATE_WATCHLIST_SUCCESS;
  watchlist: SupsWatchlistRaw;
}

export interface UpdateWatchlistFailureAction {
  type: typeof UPDATE_WATCHLIST_FAILURE;
  error?: RequestResult<void>;
}

export interface DeleteWatchlistAction {
  type: typeof DELETE_WATCHLIST;
  watchlistId: number;
}

export interface DeleteWatchlistSuccessAction {
  type: typeof DELETE_WATCHLIST_SUCCESS;
  deletedWatchlistId: number;
}

export interface DeleteWatchlistFailureAction {
  type: typeof DELETE_WATCHLIST_FAILURE;
  error?: RequestResult<void>;
}

export interface DuplicateWatchlistAction {
  type: typeof DUPLICATE_WATCHLIST;
  watchlistId: number;
  data: CreateSupsWatchlistDto;
}

export interface DuplicateWatchlistSuccessAction {
  type: typeof DUPLICATE_WATCHLIST_SUCCESS;
  watchlist: SupsWatchlistRaw;
}

export interface DuplicateWatchlistFailureAction {
  type: typeof DUPLICATE_WATCHLIST_FAILURE;
  error?: RequestResult<void>;
}

export interface ExportWatchlistAction {
  type: typeof EXPORT_WATCHLIST;
  watchlistId: number;
  watchlistDetails?: SupsWatchlistDetails;
}

export interface ExportWatchlistSuccessAction {
  type: typeof EXPORT_WATCHLIST_SUCCESS;
}

export interface ExportWatchlistFailureAction {
  type: typeof EXPORT_WATCHLIST_FAILURE;
  error?: RequestResult<void>;
}
