import { TableRowType } from '@components';
import { User } from '@redux-modules/user/interfaces';
import { SupsWatchlistPage } from '@redux-modules/watchlists/interfaces';

export enum WatchlistRole {
  Author = 'author',
  Editor = 'editor',
  Reader = 'readOnly'
}

export const getRoleByWatchlistData = (watchlist: SupsWatchlistPage, user?: User) => {
  if (watchlist.ownerId === user?.id) {
    return WatchlistRole.Author;
  }
  if (watchlist.editors.some(({ editorId }) => user?.id === editorId)) {
    return WatchlistRole.Editor;
  }
  return WatchlistRole.Reader;
};

export enum WatchlistActionName {
  Export = 'export',
  Share = 'share',
  AddEditor = 'addEditor',
  Duplicate = 'duplicate',
  Delete = 'delete',
  ChangePrivacy = 'changePrivacy',
  DeleteCompany = 'deleteCompany',
  DeleteCompanies = 'deleteCompanies',
  DeleteEditor = 'deleteEditor',
  DeleteComment = 'deleteComment',

  Unknown = ''
}
export type WatchlistAction = {
  labelKey: string;
  roles?: WatchlistRole[];
  icon: string;
  actionName: WatchlistActionName;
};
export const watchlistActions: WatchlistAction[] = [
  {
    labelKey: 'watchlists.actions.export',
    icon: 'trayArrowDownOnWhite',
    actionName: WatchlistActionName.Export
  },
  {
    labelKey: 'watchlists.actions.share',
    icon: 'shareOnWhite',
    actionName: WatchlistActionName.Share
  },
  {
    labelKey: 'watchlists.actions.addEditor',
    roles: [WatchlistRole.Author, WatchlistRole.Editor],
    icon: 'addUserOnWhite',
    actionName: WatchlistActionName.AddEditor
  },
  {
    labelKey: 'watchlists.actions.duplicate',
    icon: 'plusCircleMultipleOnWhite',
    actionName: WatchlistActionName.Duplicate
  },
  {
    labelKey: 'watchlists.actions.delete',
    roles: [WatchlistRole.Author],
    icon: 'trashOnWhite',
    actionName: WatchlistActionName.Delete
  }
];

export const getWatchlistActions: (row: TableRowType, user: User) => WatchlistAction[] = (row, user) => {
  const userRole = getRoleByWatchlistData(row as SupsWatchlistPage, user);
  return getWatchlistActionsByRole(userRole);
};

export const getWatchlistActionsByRole = (userRole?: WatchlistRole) => {
  if (!userRole) {
    return [];
  }
  return watchlistActions.filter(action => !action.roles || action.roles.some(role => userRole === role));
};

export const companySizeLabelMap = {
  MICRO: '1-9 employees',
  SMALL: '10-49 employees',
  MEDIUM: '50-249 employees',
  LARGE: '> 250 employees'
};
