import {
  RetrieveWatchlistCompaniesAction,
  RetrieveWatchlistCompaniesFailureAction,
  RETRIEVE_WATCHLIST_COMPANIES,
  RETRIEVE_WATCHLIST_COMPANIES_FAILURE,
  RETRIEVE_WATCHLIST_COMPANIES_SUCCESS,
  RetrieveWatchlistCompaniesSuccessAction,
  AddWatchlistCompanyAction,
  AddWatchlistCompanyFailureAction,
  AddWatchlistCompanySuccessAction,
  ADD_WATCHLIST_COMPANY,
  ADD_WATCHLIST_COMPANY_FAILURE,
  ADD_WATCHLIST_COMPANY_SUCCESS,
  REMOVE_WATCHLIST_COMPANY,
  REMOVE_WATCHLIST_COMPANY_FAILURE,
  RemoveWatchlistCompanySuccessAction,
  REMOVE_WATCHLIST_COMPANY_SUCCESS,
  RemoveWatchlistCompanyFailureAction,
  RemoveWatchlistCompanyAction,
  CLEAR_WATCHLIST_COMPANY_MODIFICATIONS,
  ClearWatchlistCompanyModificationsAction,
  RemoveWatchlistCompaniesAction,
  REMOVE_WATCHLIST_COMPANIES,
  RemoveWatchlistCompaniesFailureAction,
  REMOVE_WATCHLIST_COMPANIES_FAILURE,
  REMOVE_WATCHLIST_COMPANIES_SUCCESS,
  RemoveWatchlistCompaniesSuccessAction
} from './types';
import { AddWatchlistCompanyDto, SupsWatchlistCompanyRaw } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doClearWatchlistCompanyModifications = (): ClearWatchlistCompanyModificationsAction => ({
  type: CLEAR_WATCHLIST_COMPANY_MODIFICATIONS
});

export const doRetrieveWatchlistCompanies = (watchlistId: number): RetrieveWatchlistCompaniesAction => ({
  type: RETRIEVE_WATCHLIST_COMPANIES,
  watchlistId
});

export const doRetrieveWatchlistCompaniesFailure = (
  error?: RequestResult<void>
): RetrieveWatchlistCompaniesFailureAction => ({
  error,
  type: RETRIEVE_WATCHLIST_COMPANIES_FAILURE
});

export const doRetrieveWatchlistCompaniesSuccess = (
  watchlistCompanies: SupsWatchlistCompanyRaw[]
): RetrieveWatchlistCompaniesSuccessAction => ({
  type: RETRIEVE_WATCHLIST_COMPANIES_SUCCESS,
  watchlistCompanies
});

export const doAddWatchlistCompany = (
  watchlistId: number,
  data: AddWatchlistCompanyDto
): AddWatchlistCompanyAction => ({
  type: ADD_WATCHLIST_COMPANY,
  data,
  watchlistId
});

export const doAddWatchlistCompanyFailure = (error?: RequestResult<void>): AddWatchlistCompanyFailureAction => ({
  error,
  type: ADD_WATCHLIST_COMPANY_FAILURE
});

export const doAddWatchlistCompanySuccess = (company: SupsWatchlistCompanyRaw): AddWatchlistCompanySuccessAction => ({
  type: ADD_WATCHLIST_COMPANY_SUCCESS,
  company
});

export const doRemoveWatchlistCompany = (watchlistId: number, companyId: number): RemoveWatchlistCompanyAction => ({
  type: REMOVE_WATCHLIST_COMPANY,
  watchlistId,
  companyId
});

export const doRemoveWatchlistCompanyFailure = (error?: RequestResult<void>): RemoveWatchlistCompanyFailureAction => ({
  error,
  type: REMOVE_WATCHLIST_COMPANY_FAILURE
});

export const doRemoveWatchlistCompanySuccess = (
  company: SupsWatchlistCompanyRaw
): RemoveWatchlistCompanySuccessAction => ({
  type: REMOVE_WATCHLIST_COMPANY_SUCCESS,
  company
});

export const doRemoveWatchlistCompanies = (
  watchlistId: number,
  companyIds: number[]
): RemoveWatchlistCompaniesAction => ({
  type: REMOVE_WATCHLIST_COMPANIES,
  watchlistId,
  companyIds
});

export const doRemoveWatchlistCompaniesFailure = (
  error?: RequestResult<void>
): RemoveWatchlistCompaniesFailureAction => ({
  error,
  type: REMOVE_WATCHLIST_COMPANIES_FAILURE
});

export const doRemoveWatchlistCompaniesSuccess = (
  companies: SupsWatchlistCompanyRaw[]
): RemoveWatchlistCompaniesSuccessAction => ({
  type: REMOVE_WATCHLIST_COMPANIES_SUCCESS,
  companies
});
