import { AddWatchlistEditorDto, SupsWatchlistEditorDetails, SupsWatchlistEditorRaw } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';
import { User } from '@redux-modules/user/interfaces';

export const RETRIEVE_WATCHLIST_EDITORS = 'watchlists/RETRIEVE_WATCHLIST_EDITORS';
export const RETRIEVE_WATCHLIST_EDITORS_SUCCESS = 'watchlists/RETRIEVE_WATCHLIST_EDITORS_SUCCESS';
export const RETRIEVE_WATCHLIST_EDITORS_FAILURE = 'watchlists/RETRIEVE_WATCHLIST_EDITORS_FAILURE';

export const RETRIEVE_AVAILABLE_WATCHLIST_EDITORS = 'watchlists/RETRIEVE_AVAILABLE_WATCHLIST_EDITORS';
export const RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_SUCCESS = 'watchlists/RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_SUCCESS';
export const RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_FAILURE = 'watchlists/RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_FAILURE';

export const ADD_WATCHLIST_EDITOR = 'watchlists/ADD_WATCHLIST_EDITOR';
export const ADD_WATCHLIST_EDITOR_SUCCESS = 'watchlists/ADD_WATCHLIST_EDITOR_SUCCESS';
export const ADD_WATCHLIST_EDITOR_FAILURE = 'watchlists/ADD_WATCHLIST_EDITOR_FAILURE';

export const REMOVE_WATCHLIST_EDITOR = 'watchlists/REMOVE_WATCHLIST_EDITOR';
export const REMOVE_WATCHLIST_EDITOR_SUCCESS = 'watchlists/REMOVE_WATCHLIST_EDITOR_SUCCESS';
export const REMOVE_WATCHLIST_EDITOR_FAILURE = 'watchlists/REMOVE_WATCHLIST_EDITOR_FAILURE';

export const CLEAR_WATCHLIST_EDITOR_MODIFICATIONS = 'watchlists/CLEAR_WATCHLIST_EDITOR_MODIFICATIONS';
export interface ClearWatchlistEditorModificationsAction {
  type: typeof CLEAR_WATCHLIST_EDITOR_MODIFICATIONS;
}

export interface RetrieveWatchlistEditorsAction {
  type: typeof RETRIEVE_WATCHLIST_EDITORS;
  watchlistId: number;
}

export interface RetrieveWatchlistEditorsSuccessAction {
  type: typeof RETRIEVE_WATCHLIST_EDITORS_SUCCESS;
  watchlistEditors: SupsWatchlistEditorRaw[];
}

export interface RetrieveWatchlistEditorsFailureAction {
  type: typeof RETRIEVE_WATCHLIST_EDITORS_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveAvailableWatchlistEditorsAction {
  type: typeof RETRIEVE_AVAILABLE_WATCHLIST_EDITORS;
  watchlistId: number;
}

export interface RetrieveAvailableWatchlistEditorsSuccessAction {
  type: typeof RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_SUCCESS;
  users: User[];
}

export interface RetrieveAvailableWatchlistEditorsFailureAction {
  type: typeof RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_FAILURE;
  error?: RequestResult<void>;
}

export interface AddWatchlistEditorAction {
  type: typeof ADD_WATCHLIST_EDITOR;
  data: AddWatchlistEditorDto;
  watchlistId: number;
}

export interface AddWatchlistEditorSuccessAction {
  type: typeof ADD_WATCHLIST_EDITOR_SUCCESS;
  editor: SupsWatchlistEditorDetails;
}

export interface AddWatchlistEditorFailureAction {
  type: typeof ADD_WATCHLIST_EDITOR_FAILURE;
  error?: RequestResult<void>;
}

export interface RemoveWatchlistEditorAction {
  type: typeof REMOVE_WATCHLIST_EDITOR;
  watchlistId: number;
  editorId: number;
}

export interface RemoveWatchlistEditorSuccessAction {
  type: typeof REMOVE_WATCHLIST_EDITOR_SUCCESS;
  editor: SupsWatchlistEditorRaw;
}

export interface RemoveWatchlistEditorFailureAction {
  type: typeof REMOVE_WATCHLIST_EDITOR_FAILURE;
  error?: RequestResult<void>;
}
