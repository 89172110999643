import {
  RetrieveWatchlistCommentsAction,
  RetrieveWatchlistCommentsFailureAction,
  RETRIEVE_WATCHLIST_COMMENTS,
  RETRIEVE_WATCHLIST_COMMENTS_FAILURE,
  RETRIEVE_WATCHLIST_COMMENTS_SUCCESS,
  RetrieveWatchlistCommentsSuccessAction,
  AddWatchlistCommentAction,
  AddWatchlistCommentFailureAction,
  AddWatchlistCommentSuccessAction,
  ADD_WATCHLIST_COMMENT,
  ADD_WATCHLIST_COMMENT_FAILURE,
  ADD_WATCHLIST_COMMENT_SUCCESS,
  REMOVE_WATCHLIST_COMMENT,
  REMOVE_WATCHLIST_COMMENT_FAILURE,
  RemoveWatchlistCommentSuccessAction,
  REMOVE_WATCHLIST_COMMENT_SUCCESS,
  RemoveWatchlistCommentFailureAction,
  RemoveWatchlistCommentAction,
  CLEAR_WATCHLIST_COMMENT_MODIFICATIONS,
  ClearWatchlistCommentModificationsAction
} from './types';
import { AddWatchlistCommentDto, SupsWatchlistCommentDetails, SupsWatchlistCommentRaw } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doClearWatchlistCommentModifications = (): ClearWatchlistCommentModificationsAction => ({
  type: CLEAR_WATCHLIST_COMMENT_MODIFICATIONS
});

export const doRetrieveWatchlistComments = (watchlistId: number): RetrieveWatchlistCommentsAction => ({
  type: RETRIEVE_WATCHLIST_COMMENTS,
  watchlistId
});

export const doRetrieveWatchlistCommentsFailure = (
  error?: RequestResult<void>
): RetrieveWatchlistCommentsFailureAction => ({
  error,
  type: RETRIEVE_WATCHLIST_COMMENTS_FAILURE
});

export const doRetrieveWatchlistCommentsSuccess = (
  watchlistComments: SupsWatchlistCommentRaw[]
): RetrieveWatchlistCommentsSuccessAction => ({
  type: RETRIEVE_WATCHLIST_COMMENTS_SUCCESS,
  watchlistComments
});

export const doAddWatchlistComment = (
  watchlistId: number,
  data: AddWatchlistCommentDto
): AddWatchlistCommentAction => ({
  type: ADD_WATCHLIST_COMMENT,
  data,
  watchlistId
});

export const doAddWatchlistCommentFailure = (error?: RequestResult<void>): AddWatchlistCommentFailureAction => ({
  error,
  type: ADD_WATCHLIST_COMMENT_FAILURE
});

export const doAddWatchlistCommentSuccess = (
  comment: SupsWatchlistCommentDetails
): AddWatchlistCommentSuccessAction => ({
  type: ADD_WATCHLIST_COMMENT_SUCCESS,
  comment
});

export const doRemoveWatchlistComment = (watchlistId: number, commentId: number): RemoveWatchlistCommentAction => ({
  type: REMOVE_WATCHLIST_COMMENT,
  watchlistId,
  commentId
});

export const doRemoveWatchlistCommentFailure = (error?: RequestResult<void>): RemoveWatchlistCommentFailureAction => ({
  error,
  type: REMOVE_WATCHLIST_COMMENT_FAILURE
});

export const doRemoveWatchlistCommentSuccess = (
  comment: SupsWatchlistCommentRaw
): RemoveWatchlistCommentSuccessAction => ({
  type: REMOVE_WATCHLIST_COMMENT_SUCCESS,
  comment
});
