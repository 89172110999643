import { AddWatchlistCommentDto, SupsWatchlistCommentDetails, SupsWatchlistCommentRaw } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_WATCHLIST_COMMENTS = 'watchlists/RETRIEVE_WATCHLIST_COMMENTS';
export const RETRIEVE_WATCHLIST_COMMENTS_SUCCESS = 'watchlists/RETRIEVE_WATCHLIST_COMMENTS_SUCCESS';
export const RETRIEVE_WATCHLIST_COMMENTS_FAILURE = 'watchlists/RETRIEVE_WATCHLIST_COMMENTS_FAILURE';

export const ADD_WATCHLIST_COMMENT = 'watchlists/ADD_WATCHLIST_COMMENT';
export const ADD_WATCHLIST_COMMENT_SUCCESS = 'watchlists/ADD_WATCHLIST_COMMENT_SUCCESS';
export const ADD_WATCHLIST_COMMENT_FAILURE = 'watchlists/ADD_WATCHLIST_COMMENT_FAILURE';

export const REMOVE_WATCHLIST_COMMENT = 'watchlists/REMOVE_WATCHLIST_COMMENT';
export const REMOVE_WATCHLIST_COMMENT_SUCCESS = 'watchlists/REMOVE_WATCHLIST_COMMENT_SUCCESS';
export const REMOVE_WATCHLIST_COMMENT_FAILURE = 'watchlists/REMOVE_WATCHLIST_COMMENT_FAILURE';

export const CLEAR_WATCHLIST_COMMENT_MODIFICATIONS = 'watchlists/CLEAR_WATCHLIST_COMMENT_MODIFICATIONS';
export interface ClearWatchlistCommentModificationsAction {
  type: typeof CLEAR_WATCHLIST_COMMENT_MODIFICATIONS;
}

export interface RetrieveWatchlistCommentsAction {
  type: typeof RETRIEVE_WATCHLIST_COMMENTS;
  watchlistId: number;
}

export interface RetrieveWatchlistCommentsSuccessAction {
  type: typeof RETRIEVE_WATCHLIST_COMMENTS_SUCCESS;
  watchlistComments: SupsWatchlistCommentRaw[];
}

export interface RetrieveWatchlistCommentsFailureAction {
  type: typeof RETRIEVE_WATCHLIST_COMMENTS_FAILURE;
  error?: RequestResult<void>;
}

export interface AddWatchlistCommentAction {
  type: typeof ADD_WATCHLIST_COMMENT;
  data: AddWatchlistCommentDto;
  watchlistId: number;
}

export interface AddWatchlistCommentSuccessAction {
  type: typeof ADD_WATCHLIST_COMMENT_SUCCESS;
  comment: SupsWatchlistCommentDetails;
}

export interface AddWatchlistCommentFailureAction {
  type: typeof ADD_WATCHLIST_COMMENT_FAILURE;
  error?: RequestResult<void>;
}

export interface RemoveWatchlistCommentAction {
  type: typeof REMOVE_WATCHLIST_COMMENT;
  watchlistId: number;
  commentId: number;
}

export interface RemoveWatchlistCommentSuccessAction {
  type: typeof REMOVE_WATCHLIST_COMMENT_SUCCESS;
  comment: SupsWatchlistCommentRaw;
}

export interface RemoveWatchlistCommentFailureAction {
  type: typeof REMOVE_WATCHLIST_COMMENT_FAILURE;
  error?: RequestResult<void>;
}
