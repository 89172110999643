import {
  RETRIEVE_WATCHLISTS,
  RETRIEVE_WATCHLISTS_SUCCESS,
  RETRIEVE_WATCHLISTS_FAILURE,
  RetrieveWatchlistsAction,
  RetrieveWatchlistsFailureAction,
  RetrieveWatchlistsSuccessAction,
  RETRIEVE_WATCHLISTS_PAGINATED,
  RETRIEVE_WATCHLISTS_PAGINATED_SUCCESS,
  RETRIEVE_WATCHLISTS_PAGINATED_FAILURE,
  RetrieveWatchlistsPaginatedAction,
  RetrieveWatchlistsPaginatedSuccessAction,
  RetrieveWatchlistsPaginatedFailureAction,
  CREATE_WATCHLIST,
  CREATE_WATCHLIST_SUCCESS,
  CREATE_WATCHLIST_FAILURE,
  CreateWatchlistAction,
  CreateWatchlistSuccessAction,
  CreateWatchlistFailureAction,
  UPDATE_WATCHLIST,
  UPDATE_WATCHLIST_SUCCESS,
  UPDATE_WATCHLIST_FAILURE,
  UpdateWatchlistAction,
  UpdateWatchlistSuccessAction,
  UpdateWatchlistFailureAction,
  ClearWatchlistModificationsAction,
  CLEAR_WATCHLIST_MODIFICATIONS,
  RetrieveWatchlistDetailsAction,
  RETRIEVE_WATCHLIST_DETAILS,
  RETRIEVE_WATCHLIST_DETAILS_FAILURE,
  RETRIEVE_WATCHLIST_DETAILS_SUCCESS,
  RetrieveWatchlistDetailsSuccessAction,
  RetrieveWatchlistDetailsFailureAction,
  DeleteWatchlistAction,
  DeleteWatchlistFailureAction,
  DeleteWatchlistSuccessAction,
  DELETE_WATCHLIST,
  DELETE_WATCHLIST_FAILURE,
  DELETE_WATCHLIST_SUCCESS,
  DUPLICATE_WATCHLIST,
  DUPLICATE_WATCHLIST_FAILURE,
  DUPLICATE_WATCHLIST_SUCCESS,
  DuplicateWatchlistSuccessAction,
  DuplicateWatchlistFailureAction,
  DuplicateWatchlistAction,
  EXPORT_WATCHLIST,
  ExportWatchlistAction,
  EXPORT_WATCHLIST_SUCCESS,
  EXPORT_WATCHLIST_FAILURE,
  ExportWatchlistFailureAction,
  ExportWatchlistSuccessAction
} from './types';
import {
  CreateSupsWatchlistDto,
  RetrieveSupsWatchlistParams,
  SupsWatchlistDetails,
  SupsWatchlistPage,
  SupsWatchlistRaw,
  UpdateSupsWatchlistDto
} from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doClearWatchlistModifications = (): ClearWatchlistModificationsAction => ({
  type: CLEAR_WATCHLIST_MODIFICATIONS
});

export const doRetrieveWatchlistsPaginated = (
  params?: RetrieveSupsWatchlistParams
): RetrieveWatchlistsPaginatedAction => ({
  type: RETRIEVE_WATCHLISTS_PAGINATED,
  params
});

export const doRetrieveWatchlistsPaginatedFailure = (
  error?: RequestResult<void>
): RetrieveWatchlistsPaginatedFailureAction => ({
  error,
  type: RETRIEVE_WATCHLISTS_PAGINATED_FAILURE
});

export const doRetrieveWatchlistsPaginatedSuccess = (
  watchlists: SupsWatchlistPage[],
  total: number
): RetrieveWatchlistsPaginatedSuccessAction => ({
  type: RETRIEVE_WATCHLISTS_PAGINATED_SUCCESS,
  watchlists,
  total
});

export const doRetrieveWatchlists = (params?: RetrieveSupsWatchlistParams): RetrieveWatchlistsAction => ({
  type: RETRIEVE_WATCHLISTS,
  params
});

export const doRetrieveWatchlistsFailure = (error?: RequestResult<void>): RetrieveWatchlistsFailureAction => ({
  error,
  type: RETRIEVE_WATCHLISTS_FAILURE
});

export const doRetrieveWatchlistsSuccess = (watchlists: SupsWatchlistPage[]): RetrieveWatchlistsSuccessAction => ({
  type: RETRIEVE_WATCHLISTS_SUCCESS,
  watchlists
});

export const doRetrieveWatchlistDetails = (wathlistId: number): RetrieveWatchlistDetailsAction => ({
  type: RETRIEVE_WATCHLIST_DETAILS,
  wathlistId
});

export const doRetrieveWatchlistDetailsFailure = (
  error?: RequestResult<void>
): RetrieveWatchlistDetailsFailureAction => ({
  error,
  type: RETRIEVE_WATCHLIST_DETAILS_FAILURE
});

export const doRetrieveWatchlistDetailsSuccess = (
  watchlist: SupsWatchlistDetails
): RetrieveWatchlistDetailsSuccessAction => ({
  type: RETRIEVE_WATCHLIST_DETAILS_SUCCESS,
  watchlist
});

export const doCreateWatchlist = (data: CreateSupsWatchlistDto): CreateWatchlistAction => ({
  type: CREATE_WATCHLIST,
  data
});

export const doCreateWatchlistFailure = (error?: RequestResult<void>): CreateWatchlistFailureAction => ({
  error,
  type: CREATE_WATCHLIST_FAILURE
});

export const doCreateWatchlistSuccess = (watchlist: SupsWatchlistRaw): CreateWatchlistSuccessAction => ({
  type: CREATE_WATCHLIST_SUCCESS,
  watchlist
});

export const doDuplicateWatchlist = (watchlistId: number, data: CreateSupsWatchlistDto): DuplicateWatchlistAction => ({
  type: DUPLICATE_WATCHLIST,
  watchlistId,
  data
});

export const doDuplicateWatchlistFailure = (error?: RequestResult<void>): DuplicateWatchlistFailureAction => ({
  error,
  type: DUPLICATE_WATCHLIST_FAILURE
});

export const doDuplicateWatchlistSuccess = (watchlist: SupsWatchlistRaw): DuplicateWatchlistSuccessAction => ({
  type: DUPLICATE_WATCHLIST_SUCCESS,
  watchlist
});

export const doExportWatchlist = (
  watchlistId: number,
  watchlistDetails?: SupsWatchlistDetails
): ExportWatchlistAction => ({
  type: EXPORT_WATCHLIST,
  watchlistId,
  watchlistDetails
});

export const doExportWatchlistFailure = (error?: RequestResult<void>): ExportWatchlistFailureAction => ({
  error,
  type: EXPORT_WATCHLIST_FAILURE
});

export const doExportWatchlistSuccess = (): ExportWatchlistSuccessAction => ({
  type: EXPORT_WATCHLIST_SUCCESS
});

export const doUpdateWatchlist = (watchlistId: number, data: UpdateSupsWatchlistDto): UpdateWatchlistAction => ({
  type: UPDATE_WATCHLIST,
  data,
  watchlistId
});

export const doUpdateWatchlistFailure = (error?: RequestResult<void>): UpdateWatchlistFailureAction => ({
  error,
  type: UPDATE_WATCHLIST_FAILURE
});

export const doUpdateWatchlistSuccess = (watchlist: SupsWatchlistRaw): UpdateWatchlistSuccessAction => ({
  type: UPDATE_WATCHLIST_SUCCESS,
  watchlist
});

export const doDeleteWatchlist = (watchlistId: number): DeleteWatchlistAction => ({
  type: DELETE_WATCHLIST,
  watchlistId
});

export const doDeleteWatchlistFailure = (error?: RequestResult<void>): DeleteWatchlistFailureAction => ({
  error,
  type: DELETE_WATCHLIST_FAILURE
});

export const doDeleteWatchlistSuccess = (deletedWatchlistId: number): DeleteWatchlistSuccessAction => ({
  type: DELETE_WATCHLIST_SUCCESS,
  deletedWatchlistId
});
