import { AddWatchlistCompanyDto, SupsWatchlistCompanyRaw } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_WATCHLIST_COMPANIES = 'watchlists/RETRIEVE_WATCHLIST_COMPANIES';
export const RETRIEVE_WATCHLIST_COMPANIES_SUCCESS = 'watchlists/RETRIEVE_WATCHLIST_COMPANIES_SUCCESS';
export const RETRIEVE_WATCHLIST_COMPANIES_FAILURE = 'watchlists/RETRIEVE_WATCHLIST_COMPANIES_FAILURE';

export const ADD_WATCHLIST_COMPANY = 'watchlists/ADD_WATCHLIST_COMPANY';
export const ADD_WATCHLIST_COMPANY_SUCCESS = 'watchlists/ADD_WATCHLIST_COMPANY_SUCCESS';
export const ADD_WATCHLIST_COMPANY_FAILURE = 'watchlists/ADD_WATCHLIST_COMPANY_FAILURE';

export const REMOVE_WATCHLIST_COMPANY = 'watchlists/REMOVE_WATCHLIST_COMPANY';
export const REMOVE_WATCHLIST_COMPANY_SUCCESS = 'watchlists/REMOVE_WATCHLIST_COMPANY_SUCCESS';
export const REMOVE_WATCHLIST_COMPANY_FAILURE = 'watchlists/REMOVE_WATCHLIST_COMPANY_FAILURE';

export const REMOVE_WATCHLIST_COMPANIES = 'watchlists/REMOVE_WATCHLIST_COMPANIES';
export const REMOVE_WATCHLIST_COMPANIES_SUCCESS = 'watchlists/REMOVE_WATCHLIST_COMPANIES_SUCCESS';
export const REMOVE_WATCHLIST_COMPANIES_FAILURE = 'watchlists/REMOVE_WATCHLIST_COMPANIES_FAILURE';

export const CLEAR_WATCHLIST_COMPANY_MODIFICATIONS = 'watchlists/CLEAR_WATCHLIST_COMPANY_MODIFICATIONS';
export interface ClearWatchlistCompanyModificationsAction {
  type: typeof CLEAR_WATCHLIST_COMPANY_MODIFICATIONS;
}

export interface RetrieveWatchlistCompaniesAction {
  type: typeof RETRIEVE_WATCHLIST_COMPANIES;
  watchlistId: number;
}

export interface RetrieveWatchlistCompaniesSuccessAction {
  type: typeof RETRIEVE_WATCHLIST_COMPANIES_SUCCESS;
  watchlistCompanies: SupsWatchlistCompanyRaw[];
}

export interface RetrieveWatchlistCompaniesFailureAction {
  type: typeof RETRIEVE_WATCHLIST_COMPANIES_FAILURE;
  error?: RequestResult<void>;
}

export interface AddWatchlistCompanyAction {
  type: typeof ADD_WATCHLIST_COMPANY;
  data: AddWatchlistCompanyDto;
  watchlistId: number;
}

export interface AddWatchlistCompanySuccessAction {
  type: typeof ADD_WATCHLIST_COMPANY_SUCCESS;
  company: SupsWatchlistCompanyRaw;
}

export interface AddWatchlistCompanyFailureAction {
  type: typeof ADD_WATCHLIST_COMPANY_FAILURE;
  error?: RequestResult<void>;
}

export interface RemoveWatchlistCompanyAction {
  type: typeof REMOVE_WATCHLIST_COMPANY;
  watchlistId: number;
  companyId: number;
}

export interface RemoveWatchlistCompanySuccessAction {
  type: typeof REMOVE_WATCHLIST_COMPANY_SUCCESS;
  company: SupsWatchlistCompanyRaw;
}

export interface RemoveWatchlistCompanyFailureAction {
  type: typeof REMOVE_WATCHLIST_COMPANY_FAILURE;
  error?: RequestResult<void>;
}

export interface RemoveWatchlistCompaniesAction {
  type: typeof REMOVE_WATCHLIST_COMPANIES;
  watchlistId: number;
  companyIds: number[];
}

export interface RemoveWatchlistCompaniesSuccessAction {
  type: typeof REMOVE_WATCHLIST_COMPANIES_SUCCESS;
  companies: SupsWatchlistCompanyRaw[];
}

export interface RemoveWatchlistCompaniesFailureAction {
  type: typeof REMOVE_WATCHLIST_COMPANIES_FAILURE;
  error?: RequestResult<void>;
}
