import {
  RetrieveWatchlistEditorsAction,
  RetrieveWatchlistEditorsFailureAction,
  RETRIEVE_WATCHLIST_EDITORS,
  RETRIEVE_WATCHLIST_EDITORS_FAILURE,
  RETRIEVE_WATCHLIST_EDITORS_SUCCESS,
  RETRIEVE_AVAILABLE_WATCHLIST_EDITORS,
  RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_FAILURE,
  RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_SUCCESS,
  RetrieveWatchlistEditorsSuccessAction,
  AddWatchlistEditorAction,
  AddWatchlistEditorFailureAction,
  AddWatchlistEditorSuccessAction,
  ADD_WATCHLIST_EDITOR,
  ADD_WATCHLIST_EDITOR_FAILURE,
  ADD_WATCHLIST_EDITOR_SUCCESS,
  REMOVE_WATCHLIST_EDITOR,
  REMOVE_WATCHLIST_EDITOR_FAILURE,
  RemoveWatchlistEditorSuccessAction,
  REMOVE_WATCHLIST_EDITOR_SUCCESS,
  RemoveWatchlistEditorFailureAction,
  RemoveWatchlistEditorAction,
  CLEAR_WATCHLIST_EDITOR_MODIFICATIONS,
  ClearWatchlistEditorModificationsAction,
  RetrieveAvailableWatchlistEditorsAction,
  RetrieveAvailableWatchlistEditorsFailureAction,
  RetrieveAvailableWatchlistEditorsSuccessAction
} from './types';
import { AddWatchlistEditorDto, SupsWatchlistEditorDetails, SupsWatchlistEditorRaw } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';
import { User } from '@redux-modules/user/interfaces';

export const doClearWatchlistEditorModifications = (): ClearWatchlistEditorModificationsAction => ({
  type: CLEAR_WATCHLIST_EDITOR_MODIFICATIONS
});

export const doRetrieveWatchlistEditors = (watchlistId: number): RetrieveWatchlistEditorsAction => ({
  type: RETRIEVE_WATCHLIST_EDITORS,
  watchlistId
});

export const doRetrieveWatchlistEditorsFailure = (
  error?: RequestResult<void>
): RetrieveWatchlistEditorsFailureAction => ({
  error,
  type: RETRIEVE_WATCHLIST_EDITORS_FAILURE
});

export const doRetrieveWatchlistEditorsSuccess = (
  watchlistEditors: SupsWatchlistEditorRaw[]
): RetrieveWatchlistEditorsSuccessAction => ({
  type: RETRIEVE_WATCHLIST_EDITORS_SUCCESS,
  watchlistEditors
});

export const doRetrieveAvailableWatchlistEditors = (watchlistId: number): RetrieveAvailableWatchlistEditorsAction => ({
  type: RETRIEVE_AVAILABLE_WATCHLIST_EDITORS,
  watchlistId
});

export const doRetrieveAvailableWatchlistEditorsFailure = (
  error?: RequestResult<void>
): RetrieveAvailableWatchlistEditorsFailureAction => ({
  error,
  type: RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_FAILURE
});

export const doRetrieveAvailableWatchlistEditorsSuccess = (
  users: User[]
): RetrieveAvailableWatchlistEditorsSuccessAction => ({
  type: RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_SUCCESS,
  users
});

export const doAddWatchlistEditor = (watchlistId: number, data: AddWatchlistEditorDto): AddWatchlistEditorAction => ({
  type: ADD_WATCHLIST_EDITOR,
  data,
  watchlistId
});

export const doAddWatchlistEditorFailure = (error?: RequestResult<void>): AddWatchlistEditorFailureAction => ({
  error,
  type: ADD_WATCHLIST_EDITOR_FAILURE
});

export const doAddWatchlistEditorSuccess = (editor: SupsWatchlistEditorDetails): AddWatchlistEditorSuccessAction => ({
  type: ADD_WATCHLIST_EDITOR_SUCCESS,
  editor
});

export const doRemoveWatchlistEditor = (watchlistId: number, editorId: number): RemoveWatchlistEditorAction => ({
  type: REMOVE_WATCHLIST_EDITOR,
  watchlistId,
  editorId
});

export const doRemoveWatchlistEditorFailure = (error?: RequestResult<void>): RemoveWatchlistEditorFailureAction => ({
  error,
  type: REMOVE_WATCHLIST_EDITOR_FAILURE
});

export const doRemoveWatchlistEditorSuccess = (editor: SupsWatchlistEditorRaw): RemoveWatchlistEditorSuccessAction => ({
  type: REMOVE_WATCHLIST_EDITOR_SUCCESS,
  editor
});
